import axios from 'utility/axios'
import {
  GET_PARTNER_STATS,
  GET_PARTNER_PROPERTIES,
  GET_GRID_PARTNER_FROM_SLUG,
  GET_PARTNER_FROM_SLUG,
  GET_PARTNER_PUBLIC_INFO,
  GET_PARTNER_FROM_ID,
} from 'utility/endpoints'
import getNested from 'utility/getNested'
import {
  formatPropertyMetaStatus,
  formatPropertySignupStatus,
} from 'utility/property'

export const fetchPublicPartner = async ({ slug }) => {
  if (!slug || slug === '[slug]') return

  try {
    const res = await axios.get(
      `${GET_PARTNER_PUBLIC_INFO}${slug}/hometaxshield`,
    )

    if (res?.data?.stripeCouponJson)
      res.data.stripeCouponJson = JSON.parse(res?.data?.stripeCouponJson)

    if (res?.data?.stripePlanJson)
      res.data.stripePlanJson = JSON.parse(res?.data?.stripePlanJson)

    return { public: res.data }
  } catch (err) {
    console.log('Error: ', err)
    return err
  }
}

export const fetchPartner = async ({ slug, id, tenant = 'hometaxshield' }) => {
  if ((!slug && !id) || id === '[id]' || slug === '[slug]') return null

  try {
    let res
    if (slug) res = await axios.get(`${GET_PARTNER_FROM_SLUG}${slug}/${tenant}`)
    else if (id) res = await axios.get(`${GET_PARTNER_FROM_ID}${id}/${tenant}`)

    if (getNested(res, 'data', 'stripeCouponJson'))
      res.data.stripeCouponJson = JSON.parse(
        getNested(res, 'data', 'stripeCouponJson'),
      )

    if (getNested(res, 'data', 'stripePlanJson'))
      res.data.stripePlanJson = JSON.parse(
        getNested(res, 'data', 'stripePlanJson'),
      )

    return res.data
  } catch (err) {
    console.log('Error: ', err)
  }
}

export const fetchGridPartnerBySlug = async ({ slug }) => {
  if (!slug || slug === '[slug]') return null

  try {
    const res = await axios.get(`${GET_GRID_PARTNER_FROM_SLUG}${slug}`)
    return res.data
  } catch (err) {
    console.log('Error: ', err)
  }
}

export const fetchPartnerPropertiesByPartnerId = async ({
  partnerId,
  year,
  tenant = 'hometaxshield',
}) => {
  if (!partnerId || partnerId === '[partnerId]') return null

  try {
    let url = year
      ? `${GET_PARTNER_PROPERTIES}/${partnerId}/${year}/${tenant}`
      : `${GET_PARTNER_PROPERTIES}/${partnerId}/${tenant}`
    const res = await axios.get(url)
    const partnerPortfolioData = getNested(res, 'data')
    return {
      ...partnerPortfolioData,
      properties:
        partnerPortfolioData &&
        partnerPortfolioData.properties &&
        partnerPortfolioData.properties.length
          ? partnerPortfolioData.properties.map((property) => {
              return {
                id: getNested(property, 'propertyTag'),
                htsGroup: property,
                meta: {
                  signupState: formatPropertySignupStatus({ data: property }),
                  status: formatPropertyMetaStatus({ data: property }),
                },
              }
            })
          : [],
    }
  } catch (err) {
    console.log('Error: ', err)
  }
}

export const fetchPartnerStatsByPartnerId = async ({
  partnerId,
  year,
  tenant = 'hometaxshield',
}) => {
  if (!partnerId || partnerId === '[partnerId]') return null

  try {
    let url = year
      ? `${GET_PARTNER_STATS}/${partnerId}/${year}/${tenant}`
      : `${GET_PARTNER_STATS}/${partnerId}/${tenant}`
    const res = await axios.get(url)
    return res.data
  } catch (err) {
    console.log('Error: ', err)
  }
}
